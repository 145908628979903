import diagramActionTypes from "../actionTypes/layout";
import { AnyAction } from "redux";

export interface LayoutStore {
  activeToolbarItem: string;
  diagramBoxState:
    | "sql-visualizer"
    | "sql-parser"
    | "Survey"
    | "parser-sdk-sql"
    | "sql-analyzer"
    | "tune-sql";
  firstTimeFlag: boolean;
  fullscreenMode: boolean;
  isDownloadSvgClicked: boolean;
  isQueryVisualized: "sdk" | "visual" | "parse" | "format" | "optimise" | "";
  isZoomButtonClicked: boolean;
  svgResized: boolean;
  zoomMode: "auto" | "none";
  zoomState: "original" | "fit";
  editorSize: number[];
}

const initialState: LayoutStore = {
  zoomState: "original",
  isZoomButtonClicked: false,
  zoomMode: "auto",
  fullscreenMode: false,
  isDownloadSvgClicked: false,
  svgResized: false,
  diagramBoxState: "sql-visualizer",
  activeToolbarItem: "sql-visualizer",
  firstTimeFlag: true, // flag for checking first time,
  isQueryVisualized: "", // check either query is visualized before page refreshing also it is tab based
  editorSize: [500, 1220],
};

export default function layoutReducer(
  state: LayoutStore = initialState,
  action: AnyAction
): LayoutStore {
  switch (action.type) {
    case diagramActionTypes.ZOOM_ACTION:
      return {
        ...state,
        zoomState: action.payload,
      };
    case diagramActionTypes.ZOOM_BUTTON_CLICKED:
      return {
        ...state,
        isZoomButtonClicked: !state.isZoomButtonClicked,
      };
    case diagramActionTypes.SET_IS_QUERY_VISUALIZED:
      return {
        ...state,
        isQueryVisualized: action.payload,
      };
    case diagramActionTypes.ZOOM_MODE:
      return {
        ...state,
        zoomMode: action.payload,
      };
    case diagramActionTypes.FULLSCREEN_BUTTON_CLICKED:
      return {
        ...state,
        fullscreenMode: !state.fullscreenMode,
      };
    case diagramActionTypes.DOWNLOAD_SVG_BUTTON_CLICKED:
      return {
        ...state,
        isDownloadSvgClicked: !state.isDownloadSvgClicked,
      };
    case diagramActionTypes.SVG_RESIZED:
      return {
        ...state,
        svgResized: !state.svgResized,
      };
    case diagramActionTypes.DIAGRAM_BOX_STATE:
      return {
        ...state,
        diagramBoxState: action.payload,
      };
    case diagramActionTypes.ACTIVE_TOOLBAR_ITEM:
      return {
        ...state,
        activeToolbarItem: action.payload,
      };
    case diagramActionTypes.SET_FIRSTTIME_FLAG:
      return {
        ...state,
        firstTimeFlag: action.payload,
      };
    case diagramActionTypes.SET_EDITOR_SIZE:
      return {
        ...state,
        editorSize: action.payload,
      };
    default:
      return state;
  }
}
