import React, { useEffect, useState } from "react";
import { ReactComponent as CircledUpArrowSvg } from "../../assets/icons/up.svg";
import { ReactComponent as CircledDownArrowSvg } from "../../assets/icons/down.svg";
import { batch } from "react-redux";

import PatternMark from "../commonComponent/patternMark";

import { isParseDataValid } from "../../helper/util";
import {
  setDisplayedAntiPattern,
  setTabsModal,
} from "../../redux/actions/property";
import { setGlobalModalID } from "../../redux/actions/global";
import { selectAntipatternData } from "../../redux/selectors/global";
import { Diagram } from "../../redux/reducer/property";
import { AEL } from "../../helper/tsutil";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import _ from "underscore";
import { EmptyObject } from "type-fest";

export interface AntipatternBarProps {
  parseData: Diagram["optimiseData"];
  AEL: AEL[];
}

const AntipatternBar = (props: AntipatternBarProps) => {
  const dispatch = useAppDispatch();

  const antipatternData = useAppSelector(selectAntipatternData);
  const diagramBoxState = useAppSelector(
    (state) => state.layoutReducer.diagramBoxState
  );
  const { parseData, AEL } = props;
  const [showPatternList, setShowPatternList] = useState(false);
  const [selectedPatternType, setSelectedPatternType] = useState("");
  const [errorCount, setErrorCount] = useState(0);

  const antipatternAndErrorLines = AEL.flatMap((item) =>
    (item.errorsCount
      ? [
          {
            line: item.lineIndex + 1,
            count: item.errorsCount,
            type: "error",
            contents: item.errors.map((x) => ({
              type: "Error",
              description: x.message,
              pos: x.pos,
              range: x.range,
            })),
          },
        ]
      : []
    ).concat(
      item.antipatternsCount
        ? [
            {
              line: item.lineIndex + 1,
              count: item.antipatternsCount,
              type: "antipattern",
              contents: item.antipatterns.map((x) => ({
                type: "Antipattern",
                description: x.type,
                pos: x.pos,
                range: x.range,
              })),
            },
          ]
        : []
    )
  );

  useEffect(() => {
    setErrorCount(
      antipatternAndErrorLines
        .filter((x) => x.type === "error")
        .flatMap((x) => x.contents).length
    );
  }, [antipatternAndErrorLines]);

  if (!isParseDataValid(parseData)) {
    return null;
  }

  const handleTogglePattern = () => {
    setShowPatternList(!showPatternList);
  };

  const handleClickPatternWrapper = (patternType: "error" | "antipattern") => {
    if (selectedPatternType === patternType) {
      setSelectedPatternType("");
      if (
        !antipatternAndErrorLines.some(
          (pattern) => pattern.type !== selectedPatternType
        )
      ) {
        setShowPatternList(false);
      } else {
        setShowPatternList(true);
      }
    } else {
      setSelectedPatternType(patternType);
      setShowPatternList(true);
    }
  };

  const handleClickPatternLine = (
    pattern: (typeof antipatternAndErrorLines)[number],
    index: number
  ) => {
    dispatch(
      setDisplayedAntiPattern({
        pos: Array.from(new Set(pattern.contents.map((x) => x.pos))).join(";"),
        eltype: pattern.type as "error" | "antipattern",
        count: pattern.count,
        selectedPatternIndex: index,
        lineIndex: pattern.line - 1,
        range: pattern.contents[index].range,
      })
    );
    if (pattern.type === "error") {
      batch(() => {
        dispatch(setTabsModal("antipattern"));
        dispatch(setGlobalModalID("antipattern"));
      });
    } else {
      batch(() => {
        dispatch(setGlobalModalID(""));
      });
    }
  };
  let context: string = "";

  const patternsToShow = antipatternAndErrorLines.filter(
    (pattern) => !selectedPatternType || pattern.type === selectedPatternType
  );

  return (
    <div
      className={`antipattern-bar ${showPatternList ? "toggled" : ""}`}
      tabIndex={1}
      onBlur={() => setShowPatternList(false)}
    >
      <div className="content">
        {errorCount ? (
          <div
            className={`error-wrapper ${
              selectedPatternType === "error" ? "selected" : ""
            }`}
            onClick={() => {
              handleClickPatternWrapper("error");
            }}
          >
            <PatternMark
              width={20}
              color="#F02849"
              count={
                antipatternAndErrorLines
                  .filter((x) => x.type === "error")
                  .flatMap((x) => x.contents).length
              }
            />
            <div className="pattern-description">Errors</div>
          </div>
        ) : (
          ""
        )}
        {diagramBoxState === "sql-analyzer" ? (
          <div
            className={`pattern-wrapper ${
              selectedPatternType === "antipattern" ? "selected" : ""
            }`}
            onClick={() => {
              handleClickPatternWrapper("antipattern");
            }}
          >
            <PatternMark
              width={20}
              color="#F9A825"
              count={
                antipatternAndErrorLines
                  .filter((x) => x.type === "antipattern")
                  .flatMap((x) => x.contents).length
              }
            />

            <div className="pattern-description p-13">Anti patterns</div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="dropdown"
        onClick={() => {
          handleTogglePattern();
        }}
      >
        {showPatternList ? <CircledDownArrowSvg /> : <CircledUpArrowSvg />}
      </div>
      {showPatternList && (
        <div className="antipattern-toggle">
          {patternsToShow?.map((pattern, index) => {
            if (diagramBoxState !== "sql-analyzer") {
              if (pattern.type === "antipattern") return null;
            }

            return (
              <div
                className={`pattern-lines-wrapper ${pattern.type}`}
                key={pattern.count + "" + index}
              >
                <div className="line_number">
                  Line<em>{pattern.line}</em>
                </div>
                <div className="mark-container">
                  <PatternMark
                    width={17}
                    color={pattern.type === "error" ? "#F02849" : "#F9A825"}
                    count={pattern.count}
                  />
                </div>
                <div className="pattern-list">
                  {pattern.contents?.map((content, index) => {
                    let type = "";
                    if (content.type === "Antipattern") {
                      type = content.type.split("i").join("i ");
                      if (_.isEmpty(antipatternData)) {
                        context = "";
                      }
                      (
                        antipatternData as Exclude<
                          typeof antipatternData,
                          EmptyObject
                        >
                      ).data.forEach((item) => {
                        if (item.type === content.description) {
                          context = item.name;
                        }
                      });
                    } else if (content.type === "Error") {
                      type = content.type;
                      context = content.description;
                    }

                    return (
                      <div
                        className="pattern-line"
                        key={content.type + index}
                        onClick={() => handleClickPatternLine(pattern, index)}
                      >
                        <span>{type}:</span>
                        {context}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          {!patternsToShow?.length && (
            <div className="no-item">
              No {selectedPatternType || "item"} found!
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AntipatternBar;
