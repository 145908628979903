enum PropertyActionTypes {
  PROPERTY_SQL_DATA = "PROPERTY_SQL_DATA",
  PROPERTY_DIAGRAM_DATA = "PROPERTY_DIAGRAM_DATA",
  PROPERTY_PARSE_DATA = "PROPERTY_PARSE_DATA",
  PROPERTY_OPTIMISE_DATA = "PROPERTY_OPTIMISE_DATA",
  PROPERTY_SDK_DATA = "PROPERTY_SDK_DATA",
  SDK_ACTIVE_TAB = "SDK_ACTIVE_TAB",
  SET_SDK_STATE = "SET_SDK_STATE",
  PROPERTY_UPDATE_FLAG = "PROPERTY_UPDATE_FLAG",
  PROPERTY_ALIGNMENT = "PROPERTY_ALIGNMENT",
  PROPERTY_DIRECTION = "PROPERTY_DIRECTION",
  PROPERTY_EXPAND_NODE = "PROPERTY_EXPAND_NODE",
  PROPERTY_EDGE_SPAN = "PROPERTY_EDGE_SPAN",
  PROPERTY_NODE_SPAN = "PROPERTY_NODE_SPAN",
  PROPERTY_LAYER_SPAN = "PROPERTY_LAYER_SPAN",
  PROPERTY_SYMBOL_WIDTH = "PROPERTY_SYMBOL_WIDTH",
  PROPERTY_TITLE_PADDING_WIDTH = "PROPERTY_TITLE_PADDING_WIDTH",
  PROPERTY_DEFAULT_HEIGHT = "PROPERTY_DEFAULT_HEIGHT",
  PROPERTY_EXPANDED_ROW_HEIGHT = "PROPERTY_EXPANDED_ROW_HEIGHT",
  PROPERTY_OPERATOR_RADIUS = "PROPERTY_OPERATOR_RADIUS",
  PROPERTY_ICON_BUFFER_SPACE = "PROPERTY_ICON_BUFFER_SPACE",
  PROPERTY_MAX_COLUMN_RENDER = "PROPERTY_MAX_COLUMN_RENDER",
  PROPERTY_MAX_TEXT_SIZE_RENDER = "PROPERTY_MAX_TEXT_SIZE_RENDER",
  PROPERTY_EXPAND_IDS = "PROPERTY_EXPAND_IDS",
  PROPERTY_SHOW_COLUMN_ACTION = "PROPERTY_SHOW_COLUMN_ACTION",
  PROPERTY_SHOW_COLUMN_BUTTON_CLICKED = "PROPERTY_SHOW_COLUMN_BUTTON_CLICKED",
  PROPERTY_RESET = "PROPERTY_RESET",
  HIGHLIGHT_IDS = "HIGHLIGHT_IDS",
  TRANSFORM = "TRANSFORM",
  DIAGRAM_LIST = "DIAGRAM_LIST",
  ACTIVATE_DIAGRAM = "ACTIVATE_DIAGRAM",
  RENAME_DIAGRAM = "RENAME_DIAGRAM",
  NEW_DIAGRAM = "NEW_DIAGRAM",
  DIAGRM_ORDER_SET = "DIAGRM_ORDER_SET",
  NEW_TAB = "NEW_TAB",
  REMOVE_DIAGRAM = "REMOVE_DIAGRAM",
  SET_VISUALIZE_STATE = "SET_VISUALIZE_STATE",
  SET_PARSE_STATE = "SET_PARSE_STATE",
  SET_OPTIMISE_STATE = "SET_OPTIMISE_STATE",
  FORMAT_DATA = "FORMAT_DATA",
  SET_IS_FORMATTED = "SET_IS_FORMATTED",
  SHOW_ANTIPATTERN = "SHOW_ANTIPATTERN",
  SET_DISPLAYED_ANTIPATTERN = "SET_DISPLAYED_ANTIPATTERN",
  SET_DYNAMIC_NOTIFY_TEXT = "SET_DYNAMIC_NOTIFY_TEXT",
  SET_QUERY_MODIFIED = "SET_QUERY_MODIFIED",
  SET_CURSOR_POSITION = "SET_CURSOR_POSITION",
  CURRENT_DIAGRAM = "CURRENT_DIAGRAM",
  SET_USER = "SET_USER",
  LAST_ACTIVE_TAB = "LAST_ACTIVE_TAB",
  SET_RECENT_SEARCH = "SET_RECENT_SEARCH",
  SET_FORMAT_AND_PARSE = "SET_FORMAT_AND_PARSE",
  SET_IS_QUERY_OPTIMIZED = "SET_IS_QUERY_OPTIMIZED",
  FORMAT_RESPONSE = "FORMAT_RESPONSE",
  PRE_CHECK_DATA = "PRE_CHECK_DATA",
  API_TIME_OUT = "API_TIME_OUT",
  PROPERTY_BOX = "PROPERTY_BOX",
  ACTIVE_TAB = "ACTIVE_TAB",
  SET_SDK_DATA = "SET_SDK_DATA",
  SET_SDK_FORMAT = "SET_SDK_FORMAT",
  SET_SDK_DDL = "SET_SDK_DDL",
  SET_SDK_ANTIPATTERN = "SET_SDK_ANTIPATTERN",
  SET_SDK_MESSAGE = "SET_SDK_MESSAGE",
  SET_SDK_TABLE = "SET_SDK_TABLE",
  SET_SDK_FILTER = "SET_SDK_FILTER",
  SET_SDK_JOIN = "SET_SDK_JOIN",
  SET_SDK_SORT_AND_GROUP = "SET_SDK_SORT_AND_GROUP",
  SET_STREAM_ID = "SET_STREAM_ID",
  SAMPLE_TAB = "SAMPLE_TAB",
  IS_HIGHLIGHTED = " IS_HIGHLIGHTED",
  DEL_STREAM_ID = "DEL_STREAM_ID",
  CURRENT_STATE = "CURRENT_STATE",
  MARGIN_OPTION = "MARGIN_OPTION",
  SEARCH_NODES = "SEARCH_NODES",
  SET_DISABLE_REMIND_MODALS = "SET_DISABLE_REMIND_MODALS",
  SET_SELECTED_RANGE = "SET_SELECTED_RANGE",
  SET_TABS_MODAL = "SET_TABS_MODAL",
}

export default PropertyActionTypes;
