import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addSpecialTab, isSpecialTab } from "../../helper/util";
import { activateDiagram, setCurrentState } from "../../redux/actions/property";
import { useAuth } from "../../auth/AuthContext";

const useNavigateHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.propertyReducer.user);
  const recentStack = useSelector(
    (state) => state.propertyReducer.userLayout[user].recentStack
  );
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );

  const { isAuthenticated } = useAuth();

  const ScreenNames = [
    "/",
    "/sql-visualizer",
    "/sql-parser",
    "/sdk-workflow",
    "/sql-analyzer",
    "/tune-sql",
    "/parser-sdk-sql",
    "/survey-sql",
    "/about",
    "/userprofile",
  ];
  const navigateHook = ({ location }) => {
    const routeExist = ScreenNames?.filter((item) =>
      location?.includes(item)
    )?.length;
    if (ScreenNames.includes(location)) {
      if (location.includes("/sdk-workflow")) {
        addSpecialTab("SDK Downloads");
      } else if (location.includes("/survey-sql")) {
        if (isAuthenticated) {
          addSpecialTab("Survey");
        } else {
          navigate("/sql-visualizer");
          if (isSpecialTab(currentDiagram)) {
            for (let iStack = recentStack.length - 1; iStack >= 0; iStack--) {
              if (!isSpecialTab(recentStack[iStack])) {
                dispatch(activateDiagram(recentStack[iStack]));
                break;
              }
            }
          }
          return;
        }
      } else if (location.includes("/about")) {
        addSpecialTab("About");
      } else if (location.includes("/userprofile")) {
        if (isAuthenticated) {
          addSpecialTab("My Profile");
        } else {
          navigate("/sql-visualizer");
          if (isSpecialTab(currentDiagram)) {
            for (let iStack = recentStack.length - 1; iStack >= 0; iStack--) {
              if (!isSpecialTab(recentStack[iStack])) {
                dispatch(activateDiagram(recentStack[iStack]));
                break;
              }
            }
          }
          return;
        }
      } else if (isSpecialTab(currentDiagram)) {
        for (let iStack = recentStack.length - 1; iStack >= 0; iStack--) {
          if (!isSpecialTab(recentStack[iStack])) {
            batch(() => {
              dispatch(activateDiagram(recentStack[iStack]));
              dispatch(setCurrentState(location.split("/")[1].toLowerCase()));
            });
            break;
          }
        }
      }
      navigate(location);
    } else if (location.includes("/optimise-sql")) {
      navigate("/sql-analyzer");
    } else if (location.includes("/sql-analyzer")) {
      navigate("/sql-analyzer");
    } else if (location.includes("/sdk-sql")) {
      navigate("/parser-sdk-sql");
    } else if (routeExist <= 1) {
      navigate("/sql-visualizer");
      if (isSpecialTab(currentDiagram)) {
        for (let iStack = recentStack.length - 1; iStack >= 0; iStack--) {
          if (!isSpecialTab(recentStack[iStack])) {
            dispatch(activateDiagram(recentStack[iStack]));
            break;
          }
        }
      }
    }
  };
  return {
    navigateHook,
  };
};

export default useNavigateHook;
