/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useRef } from "react";
import { batch } from "react-redux";
import "./ace/mode-parseql";
import { throttle } from "underscore";
import "./ace/theme-parseql";
import FormatOptions from "./formatOptions";
import {
  fetchSampleData,
  setGlobalModalID,
  showSDKModal,
} from "../../redux/actions/global";
import { processData } from "flowhigh-sdk-js";
import { addSpecialTab, isEditorQuery } from "../../helper/util";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setUpdateFlag,
  setParseState,
  setSDKState,
  setVisualizeState,
  setCursorPosition,
  setOptimiseState,
  setOptimiseData,
  setLastActiveTab,
  setQueryOptimzed,
  setSDKData,
  setFormatData,
  setIsHighlighted,
  setFormatResponse,
  setPreCheckData,
  setExpandNodes,
  setDiagramData,
  setParseData,
  setformatState,
  setDisplayedAntiPattern,
  setDynamicNotifyText,
  setQueryModified,
  setFormatAndParseClicks,
} from "../../redux/actions/property";
import { setSampleDiagramData } from "../../redux/actions/sampleData";
import {
  fetchDiagramData,
  fetchAnalyzeData,
  fetchParseData,
  fetchOptimizeData,
  setSampleSqlData,
  setAnalyzeData,
  setAnimation,
} from "../../redux/actions/global";
import { isSpecialTab } from "../../helper/util";
// import { ReactComponent as ClipboardSvg } from "../../assets/icons/clipboard.svg";
import { ReactComponent as InfoSvg } from "../../assets/icons/info.svg";
import sampleJsonData from "../../assets/data/sample.json";
import "./sqlBox.css";
import AntipatternBar from "./antipatternBar";
import CheckMarkButton from "../commonComponent/checkMarkButton";
import { useLocation, useNavigate } from "react-router-dom";
import SampleButtonMenu from "./sampleButtonMenu";
import { setQueryButton } from "../../redux/actions/layout";
import { useMount, useUpdateEffect } from "react-use";
import EditorComponent from "./editorComponent";
import AceEditor from "react-ace";
import { LayoutStore } from "../../redux/reducer/layout";
import {
  selectIsSampleTab,
  selectSampleTab,
} from "../../redux/selectors/property";
// import { selectSampleTabData } from "../../redux/selectors/sampleData";
import { isFetchDiagramArg } from "../../client/flowhighClient";
import { components } from "../../generated-types/api";
import { AEL as AELType } from "../../helper/tsutil";
import { useAuth } from "../../auth/AuthContext";

interface SqlBoxProps {
  setFindPreviousInEditor: (findPreviousInEditor: () => void) => void;
  setFindNextInEditor: (findNextInEditor: () => void) => void;
  width: string;
}

const SqlBox = (props: SqlBoxProps) => {
  const editorR = useRef<AceEditor | null>(null);
  const width = props.width;
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.propertyReducer.user);
  const currentDiagram = useAppSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const activeDiagram = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
  );
  const diagramList = useAppSelector(
    (state) => state.propertyReducer.userLayout[user].diagramList
  );
  const sampleTab = useAppSelector(selectSampleTab);
  const recentStack = useAppSelector(
    (state) => state.propertyReducer.userLayout[user].recentStack
  );
  const formatAndParse = useAppSelector(
    (state) => state.propertyReducer.userLayout[user].formatAndParse
  );

  const isQueryOptimized = useAppSelector(
    (state) => state.propertyReducer.userLayout[user].isQueryOptimized
  );

  const diagramBoxState = useAppSelector(
    (state) => state.layoutReducer.diagramBoxState
  );
  const produceDiagramFlag = useAppSelector(
    (state) => state.globalReducer.produceDiagramFlag
  );
  const searchText = useAppSelector((state) => state.globalReducer.searchText);
  const searchTab = useAppSelector((state) => state.globalReducer.searchTab);
  const clickSearch = useAppSelector(
    (state) => state.globalReducer.clickSearch
  );
  const isShowSDKModal = useAppSelector(
    (state) => state.globalReducer.isShowSDKModal
  );
  let optimiseData = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .optimiseData
  );
  let sqlData = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram].sqlData
  );
  let visualizeState = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .visualizeState
  );
  let parseState = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .parseState
  );
  let optimiseState = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .optimiseState
  );
  let SDKState = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .SDKState
  );

  const optionMargin = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .optionMargin
  );
  const isQueryVisualized = useAppSelector(
    (state) => state.layoutReducer.isQueryVisualized
  );
  const formatResponse = useAppSelector(
    (state) => state.propertyReducer.userLayout[user].formatResponse
  );
  const preCheckData = useAppSelector(
    (state) => state.propertyReducer.userLayout[user].preCheckData
  );
  const isSampleTab = useAppSelector(selectIsSampleTab);

  const { accessToken, isAuthenticated, login: loginWithPopup } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  optimiseData = activeDiagram ? optimiseData : {};
  sqlData = activeDiagram ? sqlData : "";

  /////////// state for visualize / format / parse
  visualizeState = activeDiagram ? visualizeState : "start";
  parseState = activeDiagram ? parseState : "start";
  optimiseState = activeDiagram ? optimiseState : "start";
  SDKState = activeDiagram ? SDKState : "start";

  const [changedata, setChangedata] = useState("true");
  const [syncData, setSyncData] = useState<string | undefined>(undefined);
  const [errorCount, setErrorCount] = useState(0);
  const [antipatternCount, setAntipatternCount] = useState(0);
  const [visualize, setVisualize] = useState(false);
  const [displayAntipattern, setDisplayAntipattern] = useState(false);
  const [snackBarObj, setSnackBarObj] = useState({
    stateChange: false,
    currentTab: currentDiagram,
    afterChange: false,
    format: 0,
  });

  // const [svg, setSVG] = useState("")
  let Editor: Document | HTMLElement | null = document;

  ////////// state for smaple data
  // const [sampleData, setSampleData] = useState(sampleTab);

  //////////// satte for dialect
  // const [currentDialect, setDialect] = useState('snow');

  //////////// state for sql query data box
  const [data, setData] = useState(sqlData);
  const [option, setOption] = useState(false);
  //////////// state for format
  const [showAntiPatternBar, setShowAntipatternBar] = useState(false);
  const [AEL, setAEL] = useState<AELType[]>([]);

  const diagramOptions = {
    visualize: "sql-visualizer",
    optimise: "optimise-sql",
    parse: "sql-parser",
    tune: "tune-sql",
  };

  /////////// state for update antipattern ////////////

  // Undo / Redo
  // useEffect(() => {
  //   let editor = editorR.current.editor;

  useMount(() => {
    props.setFindNextInEditor(() => {
      return () => {
        if (!editorR?.current?.editor) {
          throw new Error("Cannot search before editor is mounted");
        }
        editorR.current.editor.findNext();
      };
    });

    props.setFindPreviousInEditor(() => {
      return () => {
        if (!editorR?.current?.editor) {
          throw new Error("Cannot search before editor is mounted");
        }
        editorR.current.editor.findPrevious();
      };
    });
  });

  /**
   * Clear optimise data when switching sample tabs
   */
  useEffect(() => {
    dispatch(setOptimiseData({}));
  }, [sampleTab]);

  useEffect(() => {
    filteredData();
  }, [sqlData, data]);

  useEffect(() => {
    if (
      snackBarObj?.stateChange === true &&
      snackBarObj?.currentTab === currentDiagram &&
      snackBarObj?.afterChange === false
    ) {
      setSnackBarObj({
        stateChange: true,
        currentTab: currentDiagram,
        afterChange: true,
        format: 0,
      });
    }
    if (editorR?.current) {
      if (Object.keys(diagramList)[0] === currentDiagram) {
        editorR.current.editor.setReadOnly(true);
      } else {
        editorR.current.editor.setReadOnly(false);
      }
    }
  }, [currentDiagram, diagramBoxState]);

  useEffect(() => {
    if (
      snackBarObj?.currentTab === currentDiagram &&
      snackBarObj?.stateChange === true &&
      snackBarObj?.afterChange === true &&
      !snackBarObj?.format &&
      currentDiagram !== Object.keys(diagramList)[0]
    ) {
      if (sqlData !== "") {
        if (diagramBoxState === diagramOptions.visualize) {
          dispatch(setDynamicNotifyText("visualQuery"));
        } else if (diagramBoxState === diagramOptions.parse) {
          dispatch(setDynamicNotifyText("parseQuery"));
        } else if (diagramBoxState === diagramOptions.optimise) {
          dispatch(setDynamicNotifyText("optimiseQuery"));
        }
      }

      setSnackBarObj({
        stateChange: false,
        currentTab: currentDiagram,
        afterChange: false,
        format: 0,
      });
    }
  }, [snackBarObj]);

  useEffect(() => {
    if (data === syncData) {
      dispatch(setDynamicNotifyText(null));
    }
  }, [data === syncData]);

  useEffect(() => {
    const LoadClickFunctionAsyncWrapper = async () => {
      try {
        await LoadClickFunction();
      } catch (e) {
        throw e;
      }
    };
    LoadClickFunctionAsyncWrapper().catch((e) => console.error(e));
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isSpecialTab(currentDiagram)) {
      if (isAuthenticated) {
        for (let iStack = recentStack.length - 1; iStack >= 0; iStack--) {
          if (!isSpecialTab(recentStack[iStack])) {
            dispatch(setLastActiveTab(recentStack[iStack]));
            break;
          }
        }
      }
    }
  }, [isAuthenticated, currentDiagram]);

  useEffect(() => {
    if (!editorR.current) {
      return;
    }

    editorR.current.editor.resize();
  }, [width]);

  // set unformatted state when option is changed,
  useEffect(() => {
    if (isAuthenticated) {
      setOption(true);
    }
  }, [optionMargin]);

  useUpdateEffect(() => {
    const updateDiagramDataAsyncWrapper = async () => {
      try {
        await updateDiagramData();
      } catch (e) {
        throw e;
      }
    };

    dispatch(setAnimation(false));
    updateDiagramDataAsyncWrapper().catch((e) => console.error(e));
  }, [produceDiagramFlag]);

  const formatSql = (anlaysedText: string[]): void => {
    if (anlaysedText === undefined || anlaysedText === null) return;
    if (anlaysedText.length === 0) {
      return;
    }
    if (!editorR.current) {
      throw new Error("Can't format SQL with no editor ref");
    }
    let sqlDataFormated = "";
    for (let iData = 0; iData < anlaysedText.length; iData++) {
      sqlDataFormated += processData(anlaysedText[iData], optionMargin);
      if (
        anlaysedText[iData].startsWith("--") ||
        anlaysedText[iData].startsWith("//")
      ) {
        sqlDataFormated += "\n";
      } else if (
        anlaysedText[iData].length > 0 &&
        iData < anlaysedText.length - 1
      ) {
        sqlDataFormated += "\n";
      }
    }
    sqlData = sqlDataFormated;
    editorR.current.editor.setValue(sqlDataFormated, -1);
  };

  const setFormatting = (
    anlaysedText: components["schemas"]["ParSeQLOutput"]["format"]
  ): string[] => {
    if (!anlaysedText) {
      return [];
    }
    const insert = (target: string, index: number, substring: string) => {
      if (index > 0) {
        return target.substring(0, index) + substring + target.substring(index);
      } else {
        return target + substring;
      }
    };

    for (let iData = 0; iData < anlaysedText.length; iData++) {
      for (let i = 0; i < anlaysedText[iData].length; i++) {
        const element = anlaysedText[iData][i];
        const nextEle = anlaysedText[iData][i + 1];
        const preEle = anlaysedText[iData][i - 1];
        if (element === "⚓") {
          if (preEle === "⛓") {
            continue;
          } else if (nextEle?.toLowerCase() !== nextEle?.toUpperCase()) {
            anlaysedText[iData] = insert(anlaysedText[iData], i + 1, " ");
          }
        }
      }
    }
    return anlaysedText;
  };

  const antipatternMarks = () => {
    let editor = editorR?.current?.editor;
    // Create anti pattern layer
    if (editor) {
      let aceScroll = editor.container.getElementsByClassName(
        "ace_scroller"
      )[0] as HTMLDivElement;
      let acegutter = editor.container.getElementsByClassName(
        "ace_gutter"
      )[0] as HTMLSpanElement;
      let acegutter_layer = editor.container.getElementsByClassName(
        "ace_gutter-layer"
      )[0] as HTMLDivElement;
      let antiPatternLayer = acegutter_layer.getElementsByClassName(
        "anti-pattern-wrapper"
      )[0] as HTMLDivElement;
      const aceWidth = Number(acegutter.style.width.split("px")[0]);
      if (aceWidth < 41) {
        acegutter.style.width = parseInt(acegutter.style.width, 10) + 10 + "px";
        acegutter_layer.style.width =
          parseInt(acegutter_layer.style.width, 10) + 10 + "px";
      }
      const aceleft = Number(aceScroll.style.left.split("px")[0]);
      if (aceleft < 44) {
        aceScroll.style.left = "45px";
      }
      let gutterLayer =
        editor.container.getElementsByClassName("ace_gutter-layer")[0];
      const cell = gutterLayer.getElementsByClassName(
        "ace_gutter-cell"
      ) as HTMLCollectionOf<HTMLSpanElement>;
      if (antiPatternLayer) {
        let elems = antiPatternLayer.getElementsByClassName(
          "anti-pattern-mark"
        ) as HTMLCollectionOf<HTMLDivElement>;
        if (cell.length < 10) {
          antiPatternLayer.style.setProperty("left", "16px", "important");
          for (let iElem = 0; iElem < elems.length; iElem++) {
            elems[iElem].style.width = "20px";
          }
        } else {
          antiPatternLayer.style.setProperty("left", "7px", "important");
          for (let iElem = 0; iElem < elems.length; iElem++) {
            elems[iElem].style.width = "27px";
          }
        }
      }
    }
  };

  setTimeout(() => {
    antipatternMarks();
  }, 100);
  // set anti pattern marks

  const checkPermission = async () => {
    if (isAuthenticated) {
      return true;
    } else {
      await loginWithPopup();
      return true;
    }
  };

  // check if sample data
  const isSampleData = useCallback(() => {
    return isSampleTab && isAuthenticated;
  }, [isSampleTab, isAuthenticated]);

  const updateDiagramData = async () => {
    dispatch(setIsHighlighted(sqlData));
    if (isSampleTab) {
      dispatch(
        fetchSampleData(
          { type: "sampleLayout" as const, sample: sampleTab },
          activeDiagram
        )
      )
        .then((response) => {
          dispatch(
            setDiagramData({
              response: response.diagram,
              currentDiagram: currentDiagram,
            })
          );

          if (response.error) {
            dispatch(setOptimiseData(response));
          }
          setSyncData(sqlData);
          if (response.diagram) {
            dispatch(setSampleDiagramData(response.diagram, sampleTab));
          }
        })
        .catch(() => {
          dispatch(setDiagramData({ currentDiagram: currentDiagram }));
        });
    } else {
      let hasPermission = await checkPermission();
      const propertiesDefined = isFetchDiagramArg(activeDiagram);
      if (!propertiesDefined) {
        throw new Error(
          "Cannot fetchDiagramData because diagram is missing required properties " +
            JSON.stringify(activeDiagram)
        );
      }
      if (hasPermission && propertiesDefined) {
        dispatch(fetchDiagramData(activeDiagram, accessToken))
          .then((response) => {
            if (!response.statement) {
              Object.assign(response, { statement: [{ eltype: "statement" }] });
            }
            dispatch(
              setDiagramData({
                response: response.diagram,
                currentDiagram: currentDiagram,
              })
            );
            dispatch(setOptimiseData(response));
            setSyncData(sqlData);
          })
          .catch(() => {
            dispatch(setDiagramData({ currentDiagram }));
          });
      } else {
        dispatch(setDiagramData({ currentDiagram }));
      }
    }
  };

  useEffect(() => {
    if (errorCount) {
      setShowAntipatternBar(true);
    } else if (antipatternCount) {
      setShowAntipatternBar(true);
    } else {
      setShowAntipatternBar(false);
    }
  }, [errorCount, antipatternCount]);

  const visualizeSqlData = async () => {
    if (!editorR?.current || editorR.current.editor.getValue() === "") return;

    dispatch(setUpdateFlag(false));

    await updateDiagramData();
    const val = editorR.current.editor.getCursorPosition();
    dispatch(setCursorPosition(val));
  };

  const handleClickedVisualize = useCallback(async () => {
    if (!isEditorQuery(editorR)) return;
    batch(() => {
      dispatch(setQueryButton("visual"));
      dispatch(setAnimation(true));
      dispatch(setQueryModified(false));
      dispatch(setDynamicNotifyText(null));
      dispatch(setExpandNodes(false));
    });
    setDisplayAntipattern(true);

    setSnackBarObj({
      stateChange: false,
      currentTab: currentDiagram,
      afterChange: true,
      format: 2,
    });
    await visualizeSqlData();
    const obj = { ...formatAndParse };
    if (!obj[currentDiagram]) {
      obj[currentDiagram] = 1;
      dispatch(setFormatAndParseClicks(obj));
    } else {
      obj[currentDiagram] = obj[currentDiagram] + 1;
      dispatch(setFormatAndParseClicks(obj));
    }
  }, [currentDiagram, formatAndParse, snackBarObj, sampleTab]);

  const handleClickedParse = async () => {
    if (!isEditorQuery(editorR)) return;
    batch(() => {
      dispatch(setQueryButton("parse"));
      dispatch(setQueryModified(false));
      dispatch(setDynamicNotifyText(null));
    });
    setDisplayAntipattern(true);
    setSnackBarObj({
      stateChange: false,
      currentTab: currentDiagram,
      afterChange: true,
      format: 2,
    });

    const obj = { ...formatAndParse };
    if (!formatAndParse[currentDiagram]) {
      obj[currentDiagram] = 1;
      dispatch(setFormatAndParseClicks(obj));
    } else {
      obj[currentDiagram] = obj[currentDiagram] + 1;
      dispatch(setFormatAndParseClicks(obj));
    }

    if (isSampleTab) {
      dispatch(
        fetchSampleData(
          { type: "sampleParse", sample: sampleTab },
          undefined,
          false
        )
      )
        .then((response) => {
          dispatch(setParseData(response));
          if (response.error) {
            dispatch(setOptimiseData(response));
          }
        })
        .catch(() => {
          dispatch(setParseData({}));
        });
    } else {
      let hasPermission = await checkPermission();
      if (hasPermission && editorR.current) {
        dispatch(
          fetchParseData(editorR.current.editor.getValue(), false, accessToken)
        )
          .then((response) => {
            if (!response.statement) {
              Object.assign(response, { statement: [{ eltype: "statement" }] });
            }
            dispatch(setParseData(response));
            dispatch(setOptimiseData(response));
          })
          .catch(() => {
            dispatch(setParseData({}));
          });
      } else {
        dispatch(setParseData({}));
      }
    }
  };

  const handleClickedSDK = async () => {
    if (!isEditorQuery(editorR)) return;
    batch(() => {
      dispatch(setQueryButton("sdk"));
      dispatch(setQueryModified(false));
      dispatch(setDynamicNotifyText(null));
    });
    setDisplayAntipattern(true);

    setSnackBarObj({
      stateChange: false,
      currentTab: currentDiagram,
      afterChange: true,
      format: 2,
    });

    if (isSampleTab) {
      dispatch(fetchSampleData({ type: "sampleFormat", sample: sampleTab }))
        .then((response) => {
          dispatch(setFormatData(response));
          if (response.error) {
            dispatch(setOptimiseData(response));
          }
        })
        .catch(() => {
          dispatch(setFormatData({}));
        });

      dispatch(
        fetchSampleData(
          { type: "sampleParse", sample: sampleTab },
          undefined,
          true
        )
      )
        .then((response) => {
          dispatch(setSDKData(response));

          if (response.error) {
            dispatch(setOptimiseData(response));
          }
        })
        .catch(() => {
          dispatch(setSDKData({}));
        });
    } else {
      let hasPermission = await checkPermission();
      if (hasPermission && sqlData) {
        dispatch(fetchAnalyzeData({ sql: sqlData }, accessToken))
          .then((response) => {
            dispatch(setFormatData(response));
            dispatch(setOptimiseData(response));
          })
          .catch(() => {
            dispatch(setFormatData({}));
          });
        dispatch(fetchParseData(sqlData, true, accessToken))
          .then((response) => {
            dispatch(setSDKData(response));
            if (response.error) {
              dispatch(setOptimiseData(response));
            }
          })
          .catch(() => {
            dispatch(setSDKData({}));
          });
      } else {
        dispatch(setSDKData({}));
      }
    }
  };

  const handleClickedOptimise = useCallback(async () => {
    if (!isEditorQuery(editorR)) return;
    batch(() => {
      dispatch(setQueryButton("optimise"));
      dispatch(setQueryModified(false));
      dispatch(setDynamicNotifyText(null));
    });
    setDisplayAntipattern(true);

    setSnackBarObj({
      stateChange: false,
      currentTab: currentDiagram,
      afterChange: true,
      format: 0,
    });

    if (isSampleTab) {
      dispatch(
        fetchSampleData(
          { type: "sampleParse", sample: sampleTab },
          undefined,
          true
        )
      )
        .then((response) => {
          dispatch(setOptimiseData(response));
        })
        .catch(() => {
          dispatch(setOptimiseData({}));
        });
    } else {
      let hasPermission = await checkPermission();
      if (hasPermission && sqlData) {
        dispatch(fetchOptimizeData(sqlData, accessToken))
          .then((response) => {
            if (!response.statement) {
              Object.assign(response, { statement: [{ eltype: "statement" }] });
            }
            dispatch(setOptimiseData(response));
          })
          .catch(() => {
            dispatch(setOptimiseData({}));
          });
      } else {
        dispatch(setOptimiseData({}));
      }
    }
    const optimizedExisiting = { ...isQueryOptimized };
    if (!optimizedExisiting[currentDiagram]) {
      optimizedExisiting[currentDiagram] = true;
      dispatch(setQueryOptimzed(optimizedExisiting));
    }
  }, [
    activeDiagram,
    isSampleData,
    dispatch,
    snackBarObj,
    sampleTab,
    diagramBoxState,
  ]);

  const CheckScreen = async () => {
    const screensPath = location.pathname?.toLowerCase();
    if (screensPath.includes("sql-visualizer")) {
      await updateDiagramData();
    } else if (screensPath.includes("sql-parser")) {
      await handleClickedParse();
    } else if (screensPath.includes("sql-analyzer")) {
      await handleClickedOptimise();
    } else if (screensPath.includes("parser-sdk-sql")) {
      await handleClickedSDK();
    } else if (screensPath.includes("tune-sql")) {
      setGlobalModalID("");
    } else {
      await updateDiagramData();
    }
  };

  useEffect(() => {
    const screensPath = location.pathname.toLowerCase();
    if (screensPath.includes("sql-visualizer")) {
      void updateDiagramData();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthenticated) {
      const CheckScreenAsyncWrapper = async () => {
        try {
          await CheckScreen();
        } catch (e) {
          throw e;
        }
      };

      batch(() => {
        dispatch(setDisplayedAntiPattern(undefined));
        changedSample(sampleTab);
        setShowAntipatternBar(false);

        CheckScreenAsyncWrapper().catch((e) => console.error(e));
      });
    }
  }, []);

  const LoadClickFunction = async () => {
    if (isAuthenticated && activeDiagram) {
      if (isQueryVisualized === "visual") {
        await updateDiagramData();
      } else if (isQueryVisualized === "parse") {
        await handleClickedParse();
      } else if (isQueryVisualized === "format") {
        await handleClickedFormat();
      } else if (isQueryVisualized === "optimise") {
        await handleClickedOptimise();
      }
      dispatch(setQueryButton(""));
    }
  };

  const filteredData = (dt: string | undefined = data) => {
    if (!dt) return;
    const predata = preCheckData ? preCheckData.replace(/\s/g, "") : "";
    const filterData = dt.replace(/\s/g, "");
    if (predata === filterData) {
      setChangedata("false");
    } else if (predata === "") {
      setChangedata("true");
    } else {
      setChangedata("true");
    }
  };

  const changedSample = (sampleName = sampleTab) => {
    if (isSampleTab) {
      batch(() => {
        dispatch(setQueryModified(false));
        dispatch(setDynamicNotifyText(null));
        dispatch(setSampleSqlData(sampleJsonData[sampleName].sql.join("\n")));
      });
    }
  };

  // handler to format
  const handleClickedFormat = async () => {
    dispatch(setQueryButton("format"));
    if (isAuthenticated) {
      let hasPermission = await checkPermission();
      if (hasPermission && changedata === "true" && sqlData) {
        setSnackBarObj({
          stateChange: false,
          currentTab: currentDiagram,
          afterChange: false,
          format: 2,
        });
        dispatch(await fetchAnalyzeData({ sql: sqlData }, accessToken))
          .then(async (res) => {
            const response = setFormatting(res.format);
            batch(() => {
              dispatch(setFormatResponse(response));
              dispatch(setAnalyzeData(response));
              dispatch(setAnimation(false));
              dispatch(setformatState("success"));
            });
            formatSql(response);
            await onFormatCall();
          })
          .catch(() => {
            dispatch(setformatState("fail"));
          });
        dispatch(setPreCheckData(data));
      } else if (option) {
        if (changedata === "false") {
          formatSql(formatResponse);
          setSnackBarObj({
            stateChange: false,
            currentTab: currentDiagram,
            afterChange: false,
            format: 2,
          });
          // sqlData should be a string by the time it gets here
          dispatch(
            await fetchAnalyzeData({ sql: sqlData as string }, accessToken)
          )
            .then(async (res) => {
              const response = setFormatting(res.format);
              batch(() => {
                dispatch(setFormatResponse(response));
                dispatch(setAnalyzeData(response));
                dispatch(setAnimation(false));
                dispatch(setformatState("success"));
              });
              formatSql(response);
              await onFormatCall();
            })
            .catch(() => {
              dispatch(setformatState("fail"));
            });
          dispatch(setPreCheckData(data));
        } else if (option) {
          if (changedata === "false") {
            formatSql(formatResponse);
            setSnackBarObj({
              stateChange: false,
              currentTab: currentDiagram,
              afterChange: false,
              format: 2,
            });
            batch(() => {
              dispatch(setAnimation(false));
            });
            await onFormatCall();
            setChangedata("false");
          }
          dispatch(setformatState("success"));
        } else if (changedata === "false") {
          batch(() => {
            dispatch(setAnimation(false));
          });
          await onFormatCall();
          setChangedata("false");
        }
        dispatch(setformatState("success"));
      } else if (changedata === "false") {
        batch(() => {
          dispatch(setformatState("success"));
        });
      }
    } else {
      await loginWithPopup();
    }
  };

  const onFormatCall = async () => {
    if (!formatAndParse[currentDiagram]) {
      return;
    } else if (formatAndParse[currentDiagram] < 1) {
      return;
    }
    const propertiesDefined = isFetchDiagramArg(activeDiagram);
    if (!propertiesDefined) {
      console.log(
        "Could not fetch diagram data because required properties were not defined on the diagram",
        activeDiagram
      );
      return;
    }
    if (diagramBoxState === diagramOptions.visualize) {
      dispatch(setIsHighlighted(sqlData));
      await dispatch(
        fetchDiagramData(
          { ...activeDiagram, sqlData: sqlData ?? "" },
          accessToken
        )
      ).then((response) => {
        if (!response.statement) {
          Object.assign(response, { statement: [{ eltype: "statement" }] });
        }
        dispatch(
          setDiagramData({
            response: response.diagram,
            currentDiagram: currentDiagram,
          })
        );
      });
    } else if (diagramBoxState === diagramOptions.parse && sqlData) {
      await dispatch(fetchParseData(sqlData, false, accessToken)).then(
        (response) => {
          dispatch(setParseData(response));
          if (response.error) {
            dispatch(setOptimiseData(response));
          }
          setSyncData(sqlData);
        }
      );
    } else if (diagramBoxState === diagramOptions.parse && sqlData) {
      await dispatch(fetchParseData(sqlData, false, accessToken)).then(
        (response) => {
          dispatch(setParseData(response));
          if (response.error) {
            dispatch(setOptimiseData(response));
          }
        }
      );
    }
  };

  const handleSnackBar = useCallback(() => {
    if (snackBarObj?.afterChange) {
      setSnackBarObj({
        stateChange: true,
        currentTab: currentDiagram,
        afterChange: true,
        format: 0,
      });
    } else if (snackBarObj?.format) {
      setSnackBarObj({
        stateChange: false,
        currentTab: currentDiagram,
        afterChange: snackBarObj?.format === 1 ? true : false,
        format: snackBarObj?.format - 1,
      });
    } else {
      setSnackBarObj({
        stateChange: true,
        currentTab: currentDiagram,
        afterChange: false,
        format: 0,
      });
    }
  }, [snackBarObj]);

  useEffect(() => {
    if (!isSpecialTab(currentDiagram)) {
      setData(activeDiagram ? sqlData : "");
      if (isSampleTab) {
        changedSample(sampleTab);
      }
    }
    // Update cursor position and highlighted range
    const editor = editorR?.current?.editor;
    if (editor) {
      const value = editorR?.current?.editor?.getCursorPosition();
      dispatch(setCursorPosition(value));
    }
  }, [currentDiagram]);

  useEffect(() => {
    const updateDiagramDataAsyncWrapper = async () => {
      try {
        await updateDiagramData();
      } catch (e) {
        throw e;
      }
    };

    changedSample();
    updateDiagramDataAsyncWrapper().catch((e) => console.error(e));
  }, [sampleTab, isAuthenticated]);

  const editorMarker = () => {
    if (clickSearch) {
      if (searchTab === "both") {
        if (diagramBoxState !== "sql-visualizer") {
          Editor = document;
        } else {
          Editor = document.getElementById("sqlEditor");
          setHighlightColors(Editor);
        }
      } else if (searchTab === "left") {
        Editor = document.getElementById("sqlEditor");
        setHighlightColors(Editor);
        removeHighlightColors(document.getElementById("parseDataEditor"));
      } else if (searchTab === "right") {
        if (diagramBoxState !== "sql-visualizer") {
          Editor = document.getElementById("parseDataEditor");
          setHighlightColors(Editor);
        }
        removeHighlightColors(document.getElementById("sqlEditor"));
      }
    }
  };

  const setHighlightColors = (
    Editor: Document | ReturnType<typeof document.getElementById> = document
  ) => {
    setTimeout(() => {
      if (Editor === null) {
        Editor = document;
      }
      const ace_editor = Editor.querySelectorAll(".ace_selected-word");
      const ace_selection = Editor.querySelectorAll(".ace_selection");
      ace_selection.forEach((item) => {
        item.classList.add("ace_selection-custom");
      });
      ace_editor.forEach((item) => {
        item.classList.add("ace_selected-word-custom");
      });
    }, 5);
  };

  const removeHighlightColors = (
    Editor: ReturnType<typeof document.getElementById>
  ) => {
    if (Editor === null) return;

    setTimeout(() => {
      const ace_editor = Editor.querySelectorAll(".ace_selected-word");
      const ace_selection = Editor.querySelectorAll(".ace_selection");
      ace_selection.forEach((item) => {
        item.classList.remove("ace_selection-custom");
      });
      ace_editor.forEach((item) => {
        item.classList.remove("ace_selected-word-custom");
      });
    }, 5);
  };

  const aceLines = document.getElementsByClassName("ace_line");
  const scrollLines = () => {
    const aceLines = document.getElementsByClassName("ace_line");
    if (aceLines) {
      for (let i = 0; i < aceLines.length; i++) {
        aceLines[i].classList.add("hover");
      }
    }
    // editorMarker();
    antipatternMarks();
  };

  useEffect(() => {
    const editor = editorR.current?.editor;
    if (!editor) {
      throw new Error("can't search without the editor ref populated");
    }
    if (searchTab === "right") {
      return;
    }
    editor.find(searchText, {
      backwards: true,
      wrap: true,
      caseSensitive: false,
      wholeWord: false,
    });
  }, [searchText, searchTab]);

  useEffect(() => {
    scrollLines();
    editorMarker();
  }, [aceLines, searchTab]);

  useEffect(() => {
    editorMarker();
  }, [clickSearch, searchText]);

  setTimeout(() => {
    scrollLines();
  }, 1000);
  useEffect(() => {
    if (visualize) {
      const visualizeSqlDataAsyncWrapper = async () => {
        try {
          await visualizeSqlData();
        } catch (e) {
          throw e;
        }
      };
      visualizeSqlDataAsyncWrapper().catch((e) => console.error(e));
      setVisualize(false);
    }
  }, [visualize]);

  const login = useCallback(() => {
    if (!isAuthenticated) {
      return loginWithPopup();
    } else {
      return;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const editor = document?.getElementById("sqlEditor");
    function handlekeydownEvent(event: KeyboardEvent) {
      const { keyCode } = event;
      if (keyCode >= 32 && keyCode <= 128) {
        const loginAsyncWrapper = async () => {
          try {
            await login();
          } catch (e) {
            throw e;
          }
        };
        loginAsyncWrapper().catch((e) => console.error(e));
      }
    }
    editor?.addEventListener("keyup", handlekeydownEvent);

    return () => editor?.removeEventListener("keyup", handlekeydownEvent);
  }, [isAuthenticated]);

  const enterCmd = async (diagramBoxState: LayoutStore["diagramBoxState"]) => {
    if (diagramBoxState === diagramOptions.visualize) {
      setVisualize(true);
    } else if (diagramBoxState === diagramOptions.parse) {
      await handleClickedParse();
    } else if (diagramBoxState === diagramOptions.optimise) {
      await handleClickedOptimise();
    }
  };

  useEffect(() => {
    const sqleditor = document.getElementById("sqlEditor");
    sqleditor?.addEventListener("click", EditSnackbar, false);
    return () => {
      sqleditor?.removeEventListener("click", EditSnackbar, false);
    };
  });

  const EditSnackbar = useCallback(
    throttle((e) => {
      if (!isSpecialTab(currentDiagram)) {
        if (
          isAuthenticated &&
          currentDiagram === Object.keys(diagramList)[0] &&
          e.isTrusted
        ) {
          dispatch(setDynamicNotifyText("edit"));
        } else {
          dispatch(setDynamicNotifyText(null));
        }
      }
    }, 1000),
    [currentDiagram, diagramList, isAuthenticated]
  );

  const handleClickedInfo = () => {
    addSpecialTab("SDK Downloads");
    navigate("/sdk-workflow");
  };

  const handleClickedInfoSvg = (e: boolean) => {
    dispatch(showSDKModal(!e));
  };

  const preventClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (
      (event.target as HTMLDivElement).classList.contains(
        "horizontal-toolbar-wrapper"
      )
    ) {
      dispatch(showSDKModal(false));
    }
  };

  const editorProps = {
    editorRefToUse: editorR,
    data,
    setData,
    scrollLines,
    errorCount,
    setErrorCount,
    AEL,
    setAEL,
    showAntiPatternBar,
    setShowAntipatternBar,
    antipatternCount,
    setAntipatternCount,
    enterCmd,
    handleSnackBar,
    filteredData,
    displayAntipattern,
    setDisplayAntipattern,
  };

  return (
    <div className="sql-pane pane">
      <div className="sql-wrapper wrapper">
        {Object.keys(diagramList)[0] === currentDiagram && <SampleButtonMenu />}
        <div
          className="sql-box pane"
          style={{
            height:
              Object.keys(diagramList)[0] === currentDiagram
                ? `calc(100% - 50px)`
                : `calc(100% - 18px)`,
          }}
        >
          <div
            style={{
              height: `calc(100% - ${showAntiPatternBar ? 96 : 48}px)`,
              display: "flex",
            }}
          >
            <EditorComponent {...editorProps} ref={editorR} />
          </div>
          {showAntiPatternBar && optimiseData && editorR?.current?.editor && (
            <AntipatternBar parseData={optimiseData} AEL={AEL} />
          )}
          <div className="bar sql-button-bar flex-space">
            <div
              className="horizontal-toolbar-wrapper sdk-align"
              onClick={preventClick}
            >
              <span className="toolbar-item ">
                <InfoSvg
                  className=""
                  data-testid="copy"
                  onClick={() => handleClickedInfoSvg(isShowSDKModal)}
                />
              </span>
              <span className="sdktext" onClick={() => handleClickedInfo()}>
                USE SDK
              </span>
              {isShowSDKModal ? (
                <div className="tooltip">
                  <div>
                    <p>
                      Download the SDK for programmatic access to FlowHigh.
                      Automate the parsing, formatting, and optimisation of SQL.
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {diagramBoxState !== "tune-sql" &&
              diagramBoxState !== "sql-analyzer" &&
              diagramBoxState !== "parser-sdk-sql" && (
                <span>
                  <FormatOptions handleClickedFormat={handleClickedFormat} />
                </span>
              )}
            {
              {
                "sql-visualizer": (
                  <span
                    className="visualize-container"
                    title="Visualize SQL (Ctrl+Enter)"
                  >
                    <CheckMarkButton
                      label="Visualise"
                      checkState={visualizeState}
                      handleClickedButton={handleClickedVisualize}
                      setCheckState={setVisualizeState}
                    />
                  </span>
                ),
                "sql-parser": (
                  <span className="parse-container" title="Parse SQL">
                    <CheckMarkButton
                      label="Parse"
                      checkState={parseState}
                      handleClickedButton={handleClickedParse}
                      setCheckState={setParseState}
                    />
                  </span>
                ),
                "sql-analyzer": (
                  <span className="parse-container" title="Parse SQL">
                    <CheckMarkButton
                      label="Analyse"
                      checkState={optimiseState}
                      handleClickedButton={handleClickedOptimise}
                      setCheckState={setOptimiseState}
                    />
                  </span>
                ),
                "parser-sdk-sql": (
                  <span className="parse-container" title="Parse SQL">
                    <CheckMarkButton
                      label="Go"
                      checkState={SDKState}
                      handleClickedButton={handleClickedSDK}
                      setCheckState={setSDKState}
                    />
                  </span>
                ),
                "tune-sql": (
                  <button
                    className="tuning-button"
                    title="Tuning"
                    id="Tuning"
                    onClick={() => {
                      dispatch(setGlobalModalID("tuning"));
                    }}
                  >
                    Tune
                  </button>
                ),
                Survey: null,
              }[diagramBoxState]
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SqlBox;
