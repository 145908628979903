import propertyActionTypes from "../actionTypes/property";
import {
  Diagram,
  HighlightIDs,
  Node,
  PropertyStore,
} from "../reducer/property";
import { Dispatch } from "../store";

export const setUpdateFlag = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_UPDATE_FLAG,
    payload: data,
  });
};

export const setAlignment = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_ALIGNMENT,
    payload: data,
  });
};

export const setDirection = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_DIRECTION,
    payload: data,
  });
};

export const setExpandNodes = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_EXPAND_NODE,
    payload: data,
  });
};

export const setEdgeSpan = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_EDGE_SPAN,
    payload: data,
  });
};

export const setNodeSpan = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_NODE_SPAN,
    payload: data,
  });
};

export const setLayerSpan = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_LAYER_SPAN,
    payload: data,
  });
};

export const setSymbolWidth = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_SYMBOL_WIDTH,
    payload: data,
  });
};

export const setTitlePaddingWidth = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_TITLE_PADDING_WIDTH,
    payload: data,
  });
};

export const setDefaultHeight = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_DEFAULT_HEIGHT,
    payload: data,
  });
};

export const setExpandedRowHeight = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_EXPANDED_ROW_HEIGHT,
    payload: data,
  });
};

export const setOperatorRadius = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_OPERATOR_RADIUS,
    payload: data,
  });
};

export const setIconBufferSpace = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_ICON_BUFFER_SPACE,
    payload: data,
  });
};

export const setMaxColumnRender = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_MAX_COLUMN_RENDER,
    payload: data,
  });
};

export const setMaxTextSizeRender = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_MAX_TEXT_SIZE_RENDER,
    payload: data,
  });
};

export const resetProperties = () => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_RESET,
  });
};

export const showCloumnButtonClicked = () => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_SHOW_COLUMN_BUTTON_CLICKED,
  });
};

export const applyShowCloumnAction =
  (data: PropertyStore["userLayout"][string]["showColumnState"]) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.PROPERTY_SHOW_COLUMN_ACTION,
      payload: data,
    });
  };
export const handlePropertyBox = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_BOX,
    payload: data,
  });
};

export const setActiveTab = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.ACTIVE_TAB,
    payload: data,
  });
};

export const setExpandIDs = (data: string[]) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_EXPAND_IDS,
    payload: data,
  });
};

export const setHighlightIDs = (data: HighlightIDs) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.HIGHLIGHT_IDS,
    payload: data,
  });
};

export const setTransform =
  (data: Diagram["transform"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.TRANSFORM,
      payload: data,
    });
  };

export const activateDiagram = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.ACTIVATE_DIAGRAM,
    payload: data,
  });
};

export const renameDiagram =
  (data: { name: string; newName: string }) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.RENAME_DIAGRAM,
      payload: data,
    });
  };

export const newDiagram =
  (data: { endpoint: string; newDiagram: string }) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.NEW_DIAGRAM,
      payload: data,
    });
  };

export const newTab = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.NEW_TAB,
    payload: data,
  });
};

export const removeDiagram = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.REMOVE_DIAGRAM,
    payload: data,
  });
};

export const setDiagramOrder = () => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.DIAGRM_ORDER_SET,
  });
};
export const setSqlData = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PROPERTY_SQL_DATA,
    payload: data,
  });
};

export const setDiagramData =
  (data: { currentDiagram: string; response?: Diagram["diagramData"] }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.PROPERTY_DIAGRAM_DATA,
      payload: data,
    });
  };

export const setIsHighlighted =
  (data: string | undefined) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.IS_HIGHLIGHTED,
      payload: data,
    });
  };

export const setParseData =
  (data: Diagram["parseData"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.PROPERTY_PARSE_DATA,
      payload: data,
    });
  };

export const setOptimiseData =
  (data: Diagram["optimiseData"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.PROPERTY_OPTIMISE_DATA,
      payload: data,
    });
  };

export const setSDKData =
  (data: Diagram["SDKData"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.PROPERTY_SDK_DATA,
      payload: data,
    });
  };

export const setSDKActiveTab = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SDK_ACTIVE_TAB,
    payload: data,
  });
};

export const setSDKState = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_STATE,
    payload: data,
  });
};

export const setformatState = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_IS_FORMATTED,
    payload: data,
  });
};

export const setParseState = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_PARSE_STATE,
    payload: data,
  });
};

export const setOptimiseState = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_OPTIMISE_STATE,
    payload: data,
  });
};

export const setFormatData =
  (data: Diagram["formatData"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.FORMAT_DATA,
      payload: data,
    });
  };

export const setVisualizeState =
  (data: Diagram["visualizeState"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_VISUALIZE_STATE,
      payload: data,
    });
  };

export const AntipatternFlag = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SHOW_ANTIPATTERN,
    payload: data,
  });
};

export const setDisplayedAntiPattern =
  (data: Diagram["displayedAntiPattern"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_DISPLAYED_ANTIPATTERN,
      payload: data,
    });
  };

export const setDynamicNotifyText =
  (data: null | string) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_DYNAMIC_NOTIFY_TEXT,
      payload: data,
    });
  };

export const setQueryModified = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_QUERY_MODIFIED,
    payload: data,
  });
};

export const setCursorPosition =
  (data: Diagram["cursorPosition"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_CURSOR_POSITION,
      payload: data,
    });
  };
export const setCurrentDiagram = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.CURRENT_DIAGRAM,
    payload: data,
  });
};

export const setUser = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_USER,
    payload: data,
  });
};

export const setLastActiveTab = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.LAST_ACTIVE_TAB,
    payload: data,
  });
};

export const setRecentSearch = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_RECENT_SEARCH,
    payload: data,
  });
};

export const setFormatAndParseClicks =
  (data: PropertyStore["userLayout"][string]["formatAndParse"]) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_FORMAT_AND_PARSE,
      payload: data,
    });
  };

export const setQueryOptimzed =
  (data: PropertyStore["userLayout"][string]["isQueryOptimized"]) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_IS_QUERY_OPTIMIZED,
      payload: data,
    });
  };

export const setFormatResponse = (data: string[]) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.FORMAT_RESPONSE,
    payload: data,
  });
};
export const setPreCheckData = (data?: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.PRE_CHECK_DATA,
    payload: data,
  });
};

export const setAPITimeOut =
  (data: Diagram["APITimeOut"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.API_TIME_OUT,
      payload: data,
    });
  };

export const setSDKFormat = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_FORMAT,
    payload: data,
  });
};

export const setStreamID =
  (data: { [id: string]: Node }) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_STREAM_ID,
      payload: data,
    });
  };

export const delStreamID = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.DEL_STREAM_ID,
    payload: data,
  });
};

export const setSDKDDL = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_DDL,
    payload: data,
  });
};
export const setSDKAntipattern = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_ANTIPATTERN,
    payload: data,
  });
};
export const setSDKMessAge = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_MESSAGE,
    payload: data,
  });
};
export const setSDKTable = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_TABLE,
    payload: data,
  });
};
export const setSDKFilter = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_FILTER,
    payload: data,
  });
};
export const setSDKJoin = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_JOIN,
    payload: data,
  });
};
export const setSDKSortGroup = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_SDK_SORT_AND_GROUP,
    payload: data,
  });
};

export const setSampleTab =
  (data: PropertyStore["userLayout"][string]["sampleTab"]) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SAMPLE_TAB,
      payload: data,
    });
  };

export const setCurrentState =
  (data: Diagram["currentEndPoint"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.CURRENT_STATE,
      payload: data,
    });
  };

export const setOptionMargin =
  (data: Diagram["optionMargin"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.MARGIN_OPTION,
      payload: data,
    });
  };
export const setSearchNodes =
  (data: Diagram["searchNodes"]) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SEARCH_NODES,
      payload: data,
    });
  };
export const setDisabledRemindModals =
  (payload: string) => (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_DISABLE_REMIND_MODALS,
      payload,
    });
  };
export const setSelectedRange =
  (
    payload: PropertyStore["userLayout"][string]["diagramList"][string]["selectedRange"]
  ) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: propertyActionTypes.SET_SELECTED_RANGE,
      payload,
    });
  };
export const setTabsModal = (tabsModal: string) => (dispatch: Dispatch) => {
  dispatch({
    type: propertyActionTypes.SET_TABS_MODAL,
    tabsModal,
  });
};
