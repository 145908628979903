import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const MetaDescriptionChanger = () => {
  const location = useLocation();

  // Dynamically setting the meta description based on route
  const getDescription = (pathname) => {
    switch (pathname) {
      case "/sql-visualizer":
        return "Discover FlowHigh SQL Visualizer – the ultimate SQL visualization tool for data experts. Visualize SQL queries, joins, schemas, and databases easily!";
      case "/sql-parser":
        return "Parse SQL queries seamlessly with FlowHigh SQL Parser Online. An intuitive, no-download SQL parsing tool for quick, accurate results. Try it now!";
      case "/sql-analyzer":
        return "Analyze and debug SQL queries with FlowHigh SQL Analyzer. Improve query performance, avoid bad SQL practices, and optimize SQL code effortlessly.";
      default:
        return "Discover FlowHigh SQL Visualizer – the ultimate SQL visualization tool for data experts. Visualize SQL queries, joins, schemas, and databases easily!";
    }
  };

  return (
    <Helmet>
      <meta name="description" content={getDescription(location.pathname)} />
    </Helmet>
  );
};

export default MetaDescriptionChanger;
