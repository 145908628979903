/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import Content from "../content";
import {
  setDiagramBoxState,
  setActiveToolbarItem,
} from "../../redux/actions/layout";
import LoaderComponent from "../commonComponent/loaderComponent";
import { useParams } from "react-router-dom";

const MainBoard = forwardRef((props, parseEditorRef) => {
  const param = useParams();
  const option = param["*"].toLowerCase();
  const dispatch = useDispatch();
  const showLoadingMask = useSelector(
    (state) => state.globalReducer.showLoadingMask
  );
  useEffect(() => {
    if (option) {
      batch(() => {
        dispatch(setDiagramBoxState(option));

        dispatch(setActiveToolbarItem(option));
      });

      // set window title
      switch (option) {
        case "sql-visualizer":
          document.title =
            "FlowHigh SQL Visualizer – The Best Tool for SQL Visualization";
          break;
        case "sql-parser":
          document.title =
            "FlowHigh SQL Parser Online - Parse SQL Queries Effortlessly";
          break;
        case "sql-analyzer":
          document.title =
            "FlowHigh SQL Analyzer – Debug & Optimize SQL Queries";
          break;
        case "parser-sdk-sql":
          document.title = "FlowHigh - SDK";
          break;
        case "tune-sql":
          document.title = "FlowHigh - SQL tuning";
          break;
        case "Survey":
          document.title = "Survey -SQL";
          break;
        default:
          break;
      }
    }
  }, [option]);

  return (
    <>
      {showLoadingMask && (
        <>
          <div className="mask overlay"></div>
          <LoaderComponent />
        </>
      )}
      <div className="flex-column" style={{ width: "100%" }}>
        <Content
          ref={parseEditorRef}
          setFindPreviousInEditor={props.setFindPreviousInEditor}
          setFindNextInEditor={props.setFindNextInEditor}
        />
      </div>
    </>
  );
});

export default MainBoard;
